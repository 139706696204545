@import "tailwindcss";
@import "./preflight.css" layer(preflight);
@import "./components.css" layer(components);
@import "./utilities.css" layer(utilities);
/* ui */
@import "./ui/DetailsGroup.css" layer(components);
@import "./ui/CornerSearchDialog.css" layer(components);
@import "./ui/SearchDialog.css" layer(components);

@plugin "@tailwindcss/aspect-ratio";
@plugin "@tailwindcss/forms";
@plugin "tailwindcss-animated";
@plugin "tailwindcss-motion";

@font-face {
	font-family: "HubotSans";
	src: url("public/assets/fonts/HubotSans.woff2") format("woff2");
	font-weight: 400 900;
	font-style: normal;
}

@theme inline {
	/* font */
	--font-hubot: "HubotSans", sans-serif;
	--sl-font-sans: var(--font-hubot);
	/* static colors */
	--color-dark: hsl(221, 19%, 26%);
	--color-darker: hsl(222, 50%, 8%);
	/* dynamic colors */
	--color-theme: light-dark(hsl(25, 100%, 50%), hsl(25, 95%, 53%));
	--color-theme-foreground: light-dark(hsl(25, 95%, 53%), hsl(25, 100%, 50%));
	--color-success: light-dark(hsl(142, 76%, 36%), hsl(142, 71%, 45%));
	--color-success-foreground: light-dark(hsl(142, 71%, 45%), hsl(142, 76%, 36%));
	--color-warning: light-dark(hsl(32, 95%, 44%), hsl(38, 92%, 50%));
	--color-warning-foreground: light-dark(hsl(38, 92%, 50%), hsl(32, 95%, 44%));
	--color-danger: light-dark(hsl(0, 72%, 51%), hsl(0, 84%, 60%));
	--color-danger-foreground: light-dark(hsl(0, 84%, 60%), hsl(0, 72%, 51%));
	--color-info: light-dark(hsl(221, 83%, 53%), hsl(217, 91%, 60%));
	--color-info-foreground: light-dark(hsl(217, 91%, 60%), hsl(221, 83%, 53%));
	--color-muted: light-dark(hsl(210 40% 96%), hsl(225 10% 12%));
	--color-muted-foreground: light-dark(hsl(215.4 16.3% 46.9%), hsl(225 10% 70%));
	--color-border: light-dark(hsl(215, 20%, 65%), hsl(215, 19%, 35%));
	--color-border-foreground: light-dark(hsl(213, 27%, 84%), hsl(215, 25%, 27%));
	--color-divider: light-dark(
		color-mix(in oklab, var(--color-gray-950) 5%, transparent),
		color-mix(in oklab, var(--color-white) 10%, transparent)
	);
	--color-divider-foreground: light-dark(
		color-mix(in oklab, var(--color-white) 10%, transparent),
		color-mix(in oklab, var(--color-gray-950) 5%, transparent)
	);
	--color-background: light-dark(hsl(210, 40%, 98%), hsl(222, 50%, 8%));
	--color-foreground: light-dark(hsl(0, 0%, 0%), hsl(0, 0%, 100%));
	--color-skeleton: light-dark(hsl(240, 6%, 90%), hsl(240, 5%, 26%));
	/* google maps */
	--color-pin-border: light-dark(hsl(25, 100%, 50%), hsl(100, 100%, 100%));
	--color-pin-background: light-dark(hsl(100, 100%, 100%), hsl(25, 100%, 50%));
	/* noisy */
	--background-image-noise: url("/public/assets/images/noise.png");
}

@variant dark {
	&:where(.dark, .dark *) {
		@slot;
	}
	@media (prefers-color-scheme: dark) {
		&:where(.system, .system *) {
			@slot;
		}
	}
}

:root {
	color-scheme: light dark;
}
.dark {
	color-scheme: dark;
}
.light {
	color-scheme: light;
}

@utility container {
	margin-inline: auto;
	padding-inline: .75rem;
}

@utility scrollbar-hide {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@utility disabled {
	pointer-events: none;
	opacity: 0.5;
	cursor: not-allowed;
	user-select: none;
}

@utility enabled {
	pointer-events: auto;
	opacity: 1;
	cursor: normal;
	user-select: auto;
}
